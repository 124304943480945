import React from "react";

import { Provider } from "react-redux";
import store from "../coms/data/store";

import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/layout";

const InternalError = () => {
  return (
    <Provider store={store}>
      <MainFrame>
        <Layout>500!!</Layout>
      </MainFrame>
    </Provider>
  );
};

export default InternalError;
